<template>
  <!-- TabForm 标签页 -->
  <!-- 
    tabLabel 数据标签  = [{
    index: tab index,
    label: tab标签名,
    type: tab显示的相关组件,
    children: [{
      model: 模型数据 form[item.model],
      label: 标签名,
      type: 组件类型
    }]
    }]
    form 表单数据 = [{
      model: 'hotel_id',
      label: '酒店/民宿 ID',
      type: 'disabled'
    }]
    tableData 表格数据 = [{
      id: '',
      name: ''
    }]
    -->
  <el-tabs class="el-tabs"
           v-model="activeName"
           type="card"
           @tab-click="tabHandleClick">
    <template v-for="item in tabLabel">
      <el-tab-pane :key="item.index"
                   :label="item.label"
                   :name="item.name"
                   v-if="item.type == 'form'">
        <CommonForm :form="form"
                    :formLabel="item.children"
                    v-bind="$attrs"
                    v-on="$listeners"
                    style="padding-right:60%"
                    @clickToAddEquip="clickToAddEquip"
                    @clickInputBtn="clickInputBtn"
                    @handlePreview="handlePreview"></CommonForm>

      </el-tab-pane>

      <!-- 富文本编辑器 -->
      <el-tab-pane :key="item.index"
                   :label="item.label"
                   :name="item.name"
                   v-if="item.type == 'wangedito'">
        <CommonWangeditor v-model.trim="form[item.model]"
                          :wangeDitorConfig="item.wangeDitorConfig"></CommonWangeditor>
      </el-tab-pane>

      <!-- 表单表格组合 -->
      <el-tab-pane class="tabTable"
                   :key="item.index"
                   :label="item.label"
                   :name="item.name"
                   v-if="item.type == 'table'">

        <!-- <el-tab-pane class="tabTable"
                   :key="item.index"
                   :name="item.name"
                   v-if="item.type == 'table'">

        <span slot="label"
              :label="item.label">
          <i>{{tableData.length}}</i>{{item.label}}
        </span> -->

        <CommonForm v-if="item.formLabel"
                    :form="item.formTable"
                    :formLabel="item.formLabel"
                    @modifychecked="getModifychecked"
                    @clickScreendate="getScreendate"
                    v-bind="$attrs"
                    v-on="$listeners"
                    style="background: #F8F7F7; padding: 12px 8px;position: relative;">
          <el-button type="primary"
                     @click="clickFormToTable()">{{item.formBtnName}}</el-button>
          <!-- <el-button type="primary"
                     @click="clickFormModify()">{{item.formModify}}</el-button> -->
        </CommonForm>
        <CommonTable :tableData="tableData"
                     :tableLabel="item.tableLabel"
                     :tableConfig="item.tableConfig"
                     @changeSwitch="changeSwitch"
                     @clickEditData="clickEditData"
                     @clickWarningData="clickWarningData"
                     @clickInfoData="clickInfoData"
                     @clickDeleteData="clickDeleteData"
                     ref="elTable"
                     v-bind="$attrs"
                     v-on="$listeners">

          <template slot="topBtn">
            <el-button v-if="item.topBtn"
                       size="mini"> 上 架 </el-button>
            <el-button v-if="item.topBtn"
                       size="mini"> 下 架 </el-button>
            <el-button v-if="item.topBtn"
                       size="mini"> 删 除 </el-button>
          </template>
        </CommonTable>
      </el-tab-pane>

      <!-- 商城模块的表格 -->
      <el-tab-pane class="tabTable"
                   :key="item.index"
                   :label="item.label"
                   :name="item.name"
                   v-if="item.type == 'shopTable'">
        <ul class="shopTitle">
          <li class="first">
            <div>商品</div>
            <div class="price">单价(元)/数量</div>
          </li>
          <li>售后</li>
          <li>收货人</li>
          <li>快递信息</li>
          <li>实收金额</li>
          <li>订单状态</li>
          <li>支付方式</li>
          <li>备注</li>
        </ul>
        <CommonTable :tableData="tableData"
                     :tableLabel="item.tableLabel"
                     :tableConfig="item.tableConfig"
                     @changeSwitch="changeSwitch"
                     @clickEditData="clickEditData"
                     @clickWarningData="clickWarningData"
                     @clickInfoData="clickInfoData"
                     @clickDeleteData="clickDeleteData"
                     ref="elTable"
                     v-bind="$attrs"
                     v-on="$listeners">

          <template slot="topBtn">
            <el-button v-if="item.topBtn"
                       size="mini"> 上 架 </el-button>
            <el-button v-if="item.topBtn"
                       size="mini"> 下 架 </el-button>
            <el-button v-if="item.topBtn"
                       size="mini"> 删 除 </el-button>
          </template>
        </CommonTable>
      </el-tab-pane>

      <el-tab-pane :key="item.index"
                   :label="item.label"
                   :name="item.name"
                   v-if="item.type == 'slot'">
        <slot :name="item.name"></slot>
      </el-tab-pane>
    </template>
  </el-tabs>
</template>

<script>
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonWangeditor from 'components/CommonWangeditor.vue'
export default {
  props: {
    tabLabel: Array,//tab 组件布局
    form: Object,//form表单数据
    tableData: Array,//table表单数据
    activeTabName: {
      type: String,
      default: 'first'
    }
  },
  components: {
    CommonForm,
    CommonWangeditor,
    CommonTable

  },
  data () {
    return {
      activeName: this.activeTabName,
    }
  },
  mounted () {
  },
  updated () {
  },
  watch: {
    activeTabName: function () {
      this.activeName = this.activeTabName
    }
  },
  updated () {
  },
  methods: {
    //表单组件的按钮点击事件
    clickInputBtn () {
      this.$emit("clickInputBtn")
    },
    // 点击tab标签跳转路径
    tabHandleClick (tab) {
      this.$emit("tabHandleClick", tab)
    },
    //开关按钮 点击事件
    changeSwitch (row) {
      this.$emit('changeSwitch', row)
    },
    //编辑按钮 点击事件
    clickEditData (row) {
      this.$emit('clickEditData', row)
    },
    //警告按钮 点击事件
    clickWarningData (row) {
      this.$emit('clickWarningData', row)
    },
    //提醒按钮 点击事件
    clickInfoData (row) {
      this.$emit('clickInfoData', row)
    },
    //删除按钮 点击事件
    clickDeleteData (row) {
      this.$emit('clickDeleteData', row)
    },
    //切换tabs项 点击事件
    clickFormToTable () {
      this.$emit('clickFormToTable')
    },
    getModifychecked (data) {
      this.$emit('getModifychecked', data)
    },
    // 已选按钮 点击事件
    clickFormModify () {
      this.$emit('clickFormModify')
    },
    //筛选按钮 点击事件
    getScreendate () {
      this.$emit('getScreendate')
    },
    //添加设备 点击事件
    clickToAddEquip (data) {
      this.$emit('clickToAddEquip', data)
    },
    //点击放大图片
    handlePreview (file) {
      this.$emit('handlePreview', file)
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-table__body-wrapper {
  height: 500px;
}
.tabTable {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
}
/deep/ .cell {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}
/deep/ .el-button {
  margin-right: 3px;
}
.shopTitle {
  width: 96%;
  height: 40px;
  background-color: #f8fbff;
  font-size: 14px;
  margin-bottom: -7px;
  li {
    list-style: none;
    float: left;
    width: 9.85%;
    padding-top: 10px;
  }
  .first {
    width: 31%;
    position: relative;
    .price {
      position: absolute;
      top: 10px;
      right: 50px;
    }
  }
}
</style>
