<template>
  <div id="Manage">
    <!-- 产品管理   商品管理 -->
    <!-- Dialog 对话框 增加、编辑-->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData">
      <CommonTabForm :form="openForm"
                     :tabLabel="TabsFormLabel"
                     :activeTabName.sync="activeTabName"
                     ref="elTable"
                     @tabHandleClick="swichTab"
                     @handleRemove="handleRemove"
                     @handlePreview="handlePreview"
                     @clickFormToTable="calculateDate"
                     @clickDeleteData="clickDeleteEquip">
      </CommonTabForm>
      <!-- 商品管理中添加规格 -->
      <div class="title"
           v-if="activeTabName == 'first' && tableFlag == true">价格库存</div>
      <div class="contet"
           style="background-color:white"
           v-if="activeTabName == 'first' && tableFlag == true">
        <!-- 上面规格部分 -->
        <div class="stand">
          <ul>
            <li class="attr"
                v-for="(item,index) in shopSpec"
                :key="index">
              <div class="spec_name">
                <span style="color:red"
                      @click="remove(index)">删除:</span>
                <span class="name">规格名:</span>
                <el-input class="title"
                          v-model="item.name"
                          style="width:200px"
                          placeholder="请输入属性">:</el-input>
              </div>
              <div class="puttAll">
                <span>规格值:</span>
                <div class="putt"
                     v-for="(subitem,i) in item.value"
                     :key="i">
                  <el-input v-model="subitem.attr"
                            placeholder="请输入值"></el-input>
                  <div class="close"
                       @click="closeAtrr(index,i)">×</div>
                </div>
              </div>

              <div class="append"
                   @click="addAtrr(index)">添加规格值</div>
            </li>

            <!-- 按钮部分 -->
            <div class="add">
              <div>
                <el-button @click="addStand">添加规格</el-button>
              </div>

            </div>
          </ul>

          <!-- 价格部分 -->
          <el-form ref="form"
                   :model="priceForm"
                   style="margin-left:-20px;">
            <el-form-item v-for="item in priceFormLabel"
                          :key="item.id"
                          style="display:inline-block;">
              <el-input v-model.trim="priceForm[item.model]"
                        :placeholder="'请输入' + item.label"
                        :style="'width:200px; margin-left:20px' "
                        v-if="!item.type">
                <template slot="append"
                          v-if="item.tip">{{item.tip}}</template>
              </el-input>

              <el-select v-model="priceForm[item.model]"
                         :placeholder="'请选择'+item.label"
                         v-if="item.type === 'select'"
                         style="margin-left:20px">
                <el-option v-for="smallItem in item.options"
                           :key="smallItem.value"
                           :label="smallItem.label"
                           :value="smallItem.value"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="add2">
            <div>
              <el-button @click="getTable">生成规格列表</el-button>
              <span style="color:#409EFF;margin-left:10px;cursor: pointer;"
                    @click="resetTable">重置表格</span>
            </div>

          </div>
        </div>

        <!-- 下面表格部分 -->
        <div class="table">
          <el-table :data="specTableData"
                    :row-key="'id'"
                    border
                    :cell-class-name="tableCellClassName"
                    @cell-click="cellClick"
                    style="width: 100%">
            <el-table-column prop="name"
                             label="属性"
                             width="180">
            </el-table-column>
            <template v-for="(item) in specTableLabel">
              <el-table-column :label="item.label"
                               :key="item.id"
                               :prop="item.prop"
                               v-if="!item.type"
                               width="110">
                <template slot-scope="scope">
                  <el-input v-model="scope.row[item.prop]"
                            placeholder=""
                            style="width:80px"></el-input>
                </template>
              </el-table-column>

              <!-- 下拉框 -->
              <el-table-column align="center"
                               v-if="item.type == 'select'"
                               show-overflow-tooltip
                               :key="item.prop"
                               :label="item.label"
                               :width="item.width || 30">
                <template slot-scope="scope">
                  <el-select v-model="scope.row[item.prop]"
                             size="mini"
                             :placeholder="'请选择'+item.label">
                    <el-option v-for="selectItem in item.options"
                               :key="selectItem.value"
                               :label="selectItem.label"
                               :value="selectItem.value">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>

              <!-- 图片 -->
              <el-table-column :label="item.label"
                               :key="item.id"
                               :prop="item.prop"
                               width="100"
                               ref="specImage"
                               v-if="item.type == 'upload'">
                <template slot-scope="scope">
                  <el-upload v-if="item.type === 'upload'"
                             class="avatar-uploader hidden-xs-only"
                             ref="upload"
                             action="#"
                             accept="image/*"
                             :http-request="function (param) { return uploadFile(param,item)}"
                             :show-file-list="false"
                             :on-preview="handlePreview"
                             :on-error="uploadError">
                    <img v-if="scope.row[item.prop]"
                         :src="scope.row[item.prop]"
                         class="avatar" />
                    <i v-else
                       class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </template>
              </el-table-column>
            </template>

          </el-table>
        </div>

      </div>

      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(openForm)">{{dialogBtnName}}</el-button>
      </div>
    </el-dialog>
    <!-- Dialog 对话框 审核表单-->
    <el-dialog :title="dialogFonfigAudit.auditName"
               class="dialogAudit"
               style="width:900px ;margin-left:30%"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfigAudit.isShowDialogVisible">
      <CommonForm :form="auditForm"
                  class="dialogAuditForm"
                  :formLabel="auditFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="danger"
                   @click="clickSubmitAuditData(-1, auditForm)">不 通 过</el-button>
        <el-button type="success"
                   @click="clickSubmitAuditData(0, auditForm)">通 过</el-button>
      </div>
    </el-dialog>
    <!-- Dialog 对话框 放大图片-->
    <el-dialog :visible.sync="dialogImgVisible"
               append-to-body>
      <img width="100%"
           :src="dialogImageUrl"
           alt="">
    </el-dialog>
    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="clickAddData"
                     icon="el-icon-circle-plus-outline">添加商品</el-button>
          <!-- <el-button type="danger" size="small" @click="clickDelData" icon="el-icon-delete">删除</el-button> -->
        </div>
        <CommonForm :form="searchForm"
                    :formLabel="searchformLabel"
                    ref="select"
                    class="filtrateInput">
          <el-button type="primary"
                     @click="searchHotelProductToAudit(searchForm)">搜 索</el-button>
          <el-button>导出</el-button>
          <el-button type="text">查看已导出列表</el-button>
          <el-button type="text"
                     @click="resetFilter(searchForm)">重置筛选条件</el-button>
        </CommonForm>

      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTabForm class="content"
                       :tabLabel="tableFormLabel"
                       :tableData="tableData"
                       @clickEditData="clickEditData"
                       @clickWarningData="clickAuditData"
                       @clickDeleteData="clickDeleteData"
                       @tabHandleClick="swichTable"
                       @getPage="getPage"
                       @changeSwitch='changeSwitch'
                       @getNumArticle="getNumArticle"></CommonTabForm>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import { getGoodsList, metGoodsList, getGoodsShow, auditGoodsList, changeGoodsStatus } from 'api/shopMoudle.js'
import { getBasisGoodsList, getBrandList } from 'api/basisDate.js'
import { uploadFile } from 'api/aliyunOss'

import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'
import CommonTabForm from 'components/CommonTab_Form.vue'

import 'element-ui/lib/theme-chalk/display.css';

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain,
    CommonTabForm,
  },
  data () {
    return {
      //主页面组件布局
      imgIndex: [],//获取删除后的图片
      searchData: '',//存放搜索的数据，分页时使用
      specTableDataAllObj: [],//规格列表全部数据
      specImgIndex: '',//规格表格中第几个图片的下标
      tableFormLabel: [
        {
          name: 'first',
          label: "仓库中",
          type: 'table',
          tableLabel: [
            {
              prop: 'no',
              label: '商品编号',
              width: '100px'
            },
            {
              prop: 'big_images',
              label: '商品图',
              width: '200px',
              type: 'image'
            },
            {
              prop: 'name',
              label: '商品名',
            },
            {
              prop: 'sales_price',
              label: '销售价'
            },
            {
              prop: 'checkout_price',
              label: '结算价',
            },
            {
              prop: 'update_time',
              label: '更新时间',
            },
            {
              prop: 'supplier_id',
              label: '供应商',
            },
            {
              prop: 'sort',
              label: '序号',
              // width: '200px'
            },
            // {
            //   prop: 'status',
            //   label: '状态',
            //   type: 'shopState'
            // },
            {
              prop: 'status',
              label: '状态信息',
              statusCode1: 2,
              statusCode12: 0,
              type: 'customSwitch'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '200px',
              type: 'operation',
              children: [
                {
                  content: '编辑',
                  type: 'primary'
                },
                // {
                //   content: '审核',
                //   type: 'warning'
                // },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'second',
          label: "未审核",
          type: 'table',
          tableLabel: [
            {
              prop: 'no',
              label: '商品编号',
              width: '100px'
            },
            {
              prop: 'big_images',
              label: '商品图',
              width: '200px',
              type: 'image'
            },
            {
              prop: 'name',
              label: '商品名',
            },
            {
              prop: 'sales_price',
              label: '销售价'
            },
            {
              prop: 'checkout_price',
              label: '结算价',
            },
            {
              prop: 'update_time',
              label: '更新时间',
            },
            {
              prop: 'supplier_id',
              label: '供应商',
            },
            {
              prop: 'sort',
              label: '序号',
              // width: '200px'
            },
            {
              prop: 'status',
              label: '状态信息',
              statusCode1: 2,
              statusCode12: 0,
              type: 'customSwitch'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '200px',
              type: 'operation',
              children: [
                {
                  content: '编辑',
                  type: 'primary'
                },
                // {
                //   content: '审核',
                //   type: 'warning'
                // },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'third',
          label: "销售中",
          type: 'table',
          tableLabel: [
            {
              prop: 'no',
              label: '商品编号',
              width: '100px'
            },
            {
              prop: 'big_images',
              label: '商品图',
              width: '200px',
              type: 'image'
            },
            {
              prop: 'name',
              label: '商品名',
            },
            {
              prop: 'sales_price',
              label: '销售价'
            },
            {
              prop: 'checkout_price',
              label: '结算价',
            },
            {
              prop: 'update_time',
              label: '更新时间',
            },
            {
              prop: 'supplier_id',
              label: '供应商',
            },
            {
              prop: 'sort',
              label: '序号',
              // width: '200px'
            },
            {
              prop: 'status',
              label: '状态信息',
              statusCode1: 2,
              statusCode12: 0,
              type: 'customSwitch'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '200px',
              type: 'operation',
              children: [
                {
                  content: '编辑',
                  type: 'primary'
                },
                // {
                //   content: '审核',
                //   type: 'warning'
                // },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'fouth',
          label: "异常商品",
          type: 'table',
          tableLabel: [
            {
              prop: 'no',
              label: '商品编号',
              width: '100px'
            },
            {
              prop: 'big_images',
              label: '商品图',
              width: '200px',
              type: 'image'
            },
            {
              prop: 'name',
              label: '商品名',
            },
            {
              prop: 'sales_price',
              label: '销售价'
            },
            {
              prop: 'checkout_price',
              label: '结算价',
            },
            {
              prop: 'update_time',
              label: '更新时间',
            },
            {
              prop: 'supplier_id',
              label: '供应商',
            },
            {
              prop: 'sort',
              label: '序号',
              // width: '200px'
            },
            {
              prop: 'status',
              label: '状态信息',
              statusCode1: 2,
              statusCode12: 0,
              type: 'customSwitch'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '200px',
              type: 'operation',
              children: [
                {
                  content: '编辑',
                  type: 'primary'
                },
                // {
                //   content: '审核',
                //   type: 'warning'
                // },
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
      ],
      //对话框 表单组件
      TabsFormLabel: [
        {
          name: 'first',
          label: "基本信息",
          type: 'form',
          children: [ //form表单 组件   
            {
              model: 'no',
              label: '商品编号',
              width: '400px',
              required: true
            },
            {
              model: 'name',
              label: '商品名称',
              width: '400px',
              required: true
            },
            {
              model: 'big_images',
              label: '封面图',
              type: 'upload',
              maxSize: 800,
              imgHeight: '1080',
              imgWidth: '808',
              clues: '建议尺寸：1080*808像素，且不超过800KB',
              required: true
            },
            {
              model: 'images',
              label: '图集',
              type: 'uploadList',
              maxSize: 800,
              imgHeight: '1080',
              imgWidth: '808',
              clues: '建议尺寸：1080*808像素，且不超过800KB',
              required: true
            },
            {
              model: 'goods_category_ids',
              label: '商品分类ID',
              height: 350,
              type: 'treeSelect',
              options: [],
              defaultProps: {
                label: "label",
                children: "children"
              },
              collapseTags: false,
              nodeKey: 'value',
              required: true
            },
            // {
            //   model: 'supplier_id',
            //   label: '所属供应商',
            //   type: 'select',
            //   options: [
            //     // value:'值',label:'标签'
            //     { value: 0, label: '观视界自营' }
            //   ]
            // },
            // {
            //   model: 'brand_id',
            //   label: '品牌ID',
            //   width: '400px',
            // },
            {
              model: 'brand_id',
              label: '品牌分类',
              type: 'treeSelect',
              height: 350,
              options: [],
              defaultProps: {
                label: "label",
                children: "children"
              },
              collapseTags: false,
              nodeKey: 'value',
            },
            {
              model: 'sort',
              label: '排序',
              width: '400px',
            },
            {
              model: 'vr_model',
              label: 'VR模型',
              width: '400px',
            },
            {
              model: 'summary',
              label: '商品简介',
              width: '400px',
            },
            {
              model: 'remark',
              label: '备注说明',
              type: 'textarea',
              width: '600px',
            },
            {
              model: 'service_info',
              label: '服务说明',
              type: 'textarea',
              width: '600px',
            },
            {
              model: 'is_sales_time',
              label: '是否开启时间限制',
              type: 'radio',
              options: [
                // value:'值',label:'标签'
                { value: 0, label: '否' },
                { value: 1, label: '是' },
              ]
            },
            {
              model: 'is_top',
              label: '是否置顶',
              type: 'radio',
              options: [
                // value:'值',label:'标签'
                { value: 0, label: '否' },
                { value: 1, label: '是' },
              ]
            },
            {
              model: 'is_recommend',
              label: '是否推荐',
              type: 'radio',
              options: [
                // value:'值',label:'标签'
                { value: 0, label: '否' },
                { value: 1, label: '是' },
              ]
            },
            {
              model: 'is_hot',
              label: '是否热门 ',
              type: 'radio',
              options: [
                // value:'值',label:'标签'
                { value: 0, label: '否' },
                { value: 1, label: '是' },
              ]
            },
            // {
            //   model: 'original_price',
            //   label: '原价',
            //   width: '300px',
            //   tip: '￥'
            // },
            // {
            //   model: 'cost_price',
            //   label: '成本价',
            //   width: '300px',
            //   tip: '￥'
            // },
            {
              model: 'sales_price',
              label: '销售价',
              width: '300px',
              tip: '￥'
            },
            // {
            //   model: 'vip_price',
            //   label: '会员价',
            //   width: '300px',
            //   tip: '￥'
            // },
            {
              model: 'checkout_price',
              label: '结算价',
              width: '300px',
              tip: '￥'
            },
            // {
            //   model: 'status',
            //   label: '状态',
            //   type: 'select',
            //   options: [
            //     // value:'值',label:'标签'
            //     { value: 0, label: '禁用' },
            //     { value: 1, label: '未审核' },
            //     { value: 2, label: '已审核' },
            //     { value: -1, label: '审核未通过' },
            //   ]
            // },
            {
              label: '其他信息',
              type: 'text2'
            },
            {
              model: 'freight_price',
              label: '运费',
              width: '300px',
              tip: '￥'
            },
            {
              model: 'stock_count',
              label: '库存',
              width: '300px',
            },
            {
              model: 'freight_info',
              label: '运费及配送说明',
              type: 'textarea',
              width: '600px',
            },
            {
              model: 'sales_begin_time',
              label: '销售开始时间',
              width: '500px',
              type: 'datetime'
            },
            {
              model: 'sales_end_time',
              label: '销售结束时间',
              width: '500px',
              type: 'datetime'
            },
          ]
        },
        {
          name: 'second',
          label: "商品介绍",
          type: 'wangedito',
          model: 'introduce',
          wangeDitorConfig: {
            height: 600,
            focus: true,
            placeholder: '请输入酒店(民宿)简介...'
          }
        },
      ],

      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '新增商品',
        editName: '编辑商品',
        isShowDialogVisible: false
      },
      // 对话框 Dialog 审核商品
      dialogFonfigAudit: {
        auditName: '待审核',
        isShowDialogVisible: false
      },

      //form表单 审核
      auditForm: {
        id: '',
        status: '',
        failed_reason: ''
      },
      auditFormLabel: [
        {
          model: 'id',
          label: '商品ID',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'failed_reason',
          label: '审核理由',
          type: 'textarea',
          width: '600px',
          required: true
        },

      ],

      //form表单 基础数据
      openForm: {
        //基本信息
        no: '',
        name: '',
        big_images: '',
        images: [],
        goods_category_ids: [],
        // supplier_id: '',
        brand_id: [],
        sort: '',
        vr_model: '',
        summary: '',
        introduce: '',
        remark: '',
        service_info: '',
        is_sales_time: '',
        is_top: '',
        is_recommend: '',
        is_hot: '',
        freight_price: '',
        freight_info: '',
        sales_begin_time: '',
        sales_end_time: '',
        status: '',
        original_price: '',
        cost_price: '',
        sales_price: '',
        vip_price: '',
        checkout_price: '',
        stock_count: ''
      },
      // form表单 搜索数据
      searchForm: {
        no_like: '',
        name: '',
        // supplier_id: '',
        sales_price1: '',
        sales_price2: '',
        vip_price1: '',
        vip_price2: '',
        sales_begin_time1: '',
        sales_begin_time2: '',
        sales_end_time1: '',
        sales_end_time2: '',
        is_sales_time: '',
        is_top: '',
        is_recommend: '',
        is_hot: ''
      },
      searchformLabel: [
        {
          model: 'no_like',
          label: '商品编号',
          width: '304px',
        },
        {
          model: 'name',
          label: '商品名',
          width: '304px',
        },
        // {
        //   model: 'supplier_id',
        //   label: '供应商名称',
        //   width: '304px',
        //   type: 'select',
        //   options: [
        //     {
        //       value: 0,
        //       label: '观视界自营'
        //     },
        //   ],
        // },
        {
          label: '销售价格',
          type: 'inputArea',
          model1: 'sales_price1',
          model2: 'sales_price2',
          tip: '￥'
        },
        // {
        //   label: '会员价格',
        //   type: 'inputArea',
        //   model1: 'vip_price1',
        //   model2: 'vip_price2',
        //   tip: '￥'
        // },
        {
          model1: 'sales_begin_time1',
          model2: 'sales_begin_time2',
          label: '销售开始时间',
          width2: '200px',
          type: 'datetimeArea'
        },
        {
          model1: 'sales_end_time1',
          model2: 'sales_end_time2',
          label: '销售结束时间',
          width2: '200px',
          type: 'datetimeArea'
        },
        {
          model: 'is_top',
          label: '是否置顶',
          width: '240px',
          type: 'select',
          options: [
            { value: 0, label: '否' },
            { value: 1, label: '是' },
          ],
        },
        {
          model: 'is_recommend',
          label: '是否推荐',
          width: '240px',
          type: 'select',
          options: [
            { value: 0, label: '否' },
            { value: 1, label: '是' },
          ],
        },
        {
          model: 'is_hot',
          label: '是否热门',
          width: '240px',
          type: 'select',
          options: [
            { value: 0, label: '否' },
            { value: 1, label: '是' },
          ],
        },
        {
          model: 'is_sales_time',
          label: '是否开启时间限制',
          width: '240px',
          type: 'select',
          options: [
            { value: 0, label: '否' },
            { value: 1, label: '是' },
          ],
        },
      ],

      // table 表格 基础数据
      tableData: [

      ],

      // 商品-全部-数据
      hotelAllDataObj: {},
      optionsGoods: [],//商品分类数据
      optionsBrand: [],//品牌分类数据
      // catgory_id最大值
      MaxId: '',
      //待审核 或 审核未通过 Name
      activeTableName: 'first',
      //Dialog新增/编辑 Name
      activeTabName: 'first',
      //Dialog 放大图片
      dialogImgVisible: false,
      dialogImageUrl: '',

      //提交保存信息的 按钮名称
      dialogBtnName: '保存',
      tabIndex: 0,//存放tab的下标
      shopSpec: [],
      tableFlag: true,//判断是否出现表格


      //规格明细数据
      //表单上表格的数据
      specTableData: [],
      specTableLabel: [
        {
          prop: 'no',
          label: '规格编码',
        },
        // {
        //   prop: 'original_price',
        //   label: '原价',
        // },
        // {
        //   prop: 'cost_price',
        //   label: '成本价'
        // },
        {
          prop: 'sales_price',
          label: '销售价'
        },
        {
          prop: 'checkout_price',
          label: '结算价',
        },
        // {
        //   prop: 'vip_price',
        //   label: '会员价'
        // },
        {
          prop: 'stock_count',
          label: '库存'
        },
        {
          prop: 'freight_price',
          label: '运费'
        },
        {
          prop: 'image',
          label: '图片',
          type: 'upload',
          maxSize: 500,
          imgHeight: '1080',
          imgWidth: '808',
        },
        {
          prop: 'is_coupon',
          label: '可否用券',
          type: 'select',
          width: '100',
          options: [
            { value: 0, label: '不能' },
            { value: 1, label: '能' },
          ]
        },
        {
          prop: 'integral',
          label: '可使用的积分'
        },
        {
          prop: 'sales_count',
          label: '销量'
        },
        {
          prop: 'note',
          label: '备注'
        },
      ],
      priceForm: {
        no: '',
        original_price: '',
        cost_price: '',
        sales_price: '',
        vip_price: '',
        freight_price: '',
        stock_count: '',
        integral: '',
        checkout_price: '',
        sales_count: '',
        note: '',
        is_coupon: '',
        image: ''
      },
      priceFormLabel: [
        // {
        //   model: 'original_price',
        //   label: '原价',
        //   tip: '￥'
        // },
        // {
        //   model: 'cost_price',
        //   label: '成本价',
        //   tip: '￥'
        // },
        {
          model: 'sales_price',
          label: '销售价',
          tip: '￥'
        },
        {
          model: 'checkout_price',
          label: '结算价',
          tip: '￥'
        },
        // {
        //   model: 'vip_price',
        //   label: '会员价',
        //   tip: '￥'
        // },
        {
          model: 'freight_price',
          label: '运费',
          tip: '￥'
        },
        {
          model: 'stock_count',
          label: '库存',
        },
        {
          model: 'integral',
          label: '可使用的积分',
        },
        {
          model: 'sales_count',
          label: '销量',
        },
        {
          model: 'note',
          label: '备注',
        },
        {
          model: 'is_coupon',
          label: '可否用券',
          type: 'select',
          options: [
            { value: 0, label: '不能' },
            { value: 1, label: '能' },
          ]
        }
      ],
      input: '',
      isTable: false,
      shopSpec: [],
      listL: [],
      group: [],
      formFlag: false,
    }
  },

  mounted () {
    //初始table数据
    this.getGoodsReserveList();
    // this.getBasisGoodsList()
  },

  watch: {
    'openForm.sales_price' (newData) {
      this.priceForm.sales_price = newData
    },
    'openForm.checkout_price' (newData) {
      this.priceForm.checkout_price = newData
    },
    'openForm.freight_price' (newData) {
      this.priceForm.freight_price = newData
    },
    // 将字段转换为Elementui能用的字段
    hotelAllDataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.hotelAllDataObj.list,
        {
        },
        0
      )
    },
    specTableDataAllObj: function () {
      this.specTableData = this.dataFormatDeal(
        this.specTableDataAllObj,
        {
        },
        0
      )
    },
    activeTabName: function () {
      if (this.activeTabName == "second") {
        this.dialogBtnName = "保 存"
      } else {
        this.dialogBtnName = "下一步"
      }
    },
    //商品分类选择
    optionsGoods: function () {
      this.TabsFormLabel[0].children[4].options = this.dataFormatDeal(this.optionsGoods, {
        id: 'value',
        category_name: 'label',
        child: 'children',
      }, 1)
    },
    //品牌分类选择
    optionsBrand: function () {
      this.TabsFormLabel[0].children[5].options = this.dataFormatDeal(this.optionsBrand, {
        id: 'value',
        name: 'label',
        child: 'children',
      }, 1)
    },
  },
  methods: {
    //商品分类数据
    getBasisGoodsList () {
      return new Promise(resolve => {
        getBasisGoodsList().then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.optionsGoods = DATA.data.list
            resolve(DATA.data)
          } else {
            this.$message({
              type: 'error',
              message: '已有商品获取失败'
            })
          }
        })
      })
    },
    //品牌分类数据
    getBrandList () {
      return new Promise(resolve => {
        getBrandList().then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            var arr = []
            DATA.data.forEach(item => {
              if (item.status == 1) {
                arr.push(item)
              }
            })
            this.optionsBrand = arr
            resolve(DATA.data)
          } else {
            this.$message({
              type: 'error',
              message: '已有商品获取失败'
            })
          }
        })
      })
    },
    //商品仓库中，未审核，销售中请求接口
    getGoodsReserveList (data) {
      if (data) {
        getGoodsList(data).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[this.tabIndex].tableConfig.total = DATA.data.total
            this.tableFormLabel[this.tabIndex].tableConfig.loading = false
            console.log(this.hotelAllDataObj)
          }

        })
      } else {
        getGoodsList({
          status: this.tabIndex,
          page: this.tableFormLabel[this.tabIndex].tableConfig.page,
          page_rows: this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
        }).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[this.tabIndex].tableConfig.total = DATA.data.total
            this.tableFormLabel[this.tabIndex].tableConfig.loading = false
            console.log(this.hotelAllDataObj)
          }

        })
      }

    },

    //异常商品请求接口
    getGoodsOperationList (data) {
      if (data) {
        getGoodsList(data).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data
            this.tableFormLabel[3].tableConfig.total = DATA.data.total
            this.tableFormLabel[3].tableConfig.loading = false
            // console.log(DATA)
          }

        })
      } else {
        getGoodsList({
          status: -1,
          page: this.tableFormLabel[3].tableConfig.page,
          page_rows: this.tableFormLabel[3].tableConfig.NumArticle
        }).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data
            this.tableFormLabel[3].tableConfig.total = DATA.data.total
            this.tableFormLabel[3].tableConfig.loading = false
            // console.log(DATA)
          }

        })
      }

    },
    //获得下列分类页的页数
    getPage () {
      if (this.activeTableName == 'first' || this.activeTableName == 'second' || this.activeTableName == 'third') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[this.tabIndex].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
          this.getGoodsReserveList(this.searchData)
        } else {
          this.getGoodsReserveList()
        }
      } else {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[3].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[3].tableConfig.NumArticle
          this.getGoodsOperationList(this.searchData)
        } else {
          this.getGoodsOperationList()
        }
      }
    },

    //获取下面分页的页数的最大条数
    getNumArticle (NumArticle) {
      if (this.activeTableName == 'first' || this.activeTableName == 'second' || this.activeTableName == 'third') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[this.tabIndex].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
          this.getGoodsReserveList(this.searchData)
        } else {
          this.getGoodsReserveList()
        }
      } else {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[3].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[3].tableConfig.NumArticle
          this.getGoodsOperationList(this.searchData)
        } else {
          this.getGoodsOperationList()
        }
      }
    },
    //新增、 编辑商品 请求接口
    metGoodsList (met, data) {
      return new Promise(resolve => {
        metGoodsList(met, data).then(res => {
          const DATA = res.data
          resolve(DATA)
          console.log(DATA)
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
          } else {
            this.$message({
              message: DATA.message,
            })
          }
        })
      })
    },

    //审核商品  接口
    auditGoodsList (data) {
      return new Promise(resolve => {
        auditGoodsList(data).then(res => {
          const DATA = res.data
          resolve(DATA)
          console.log(DATA)
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
          } else {
            this.$message({
              message: DATA.message,
            })
          }
        })
      })
    },

    //改变商品状态
    changeGoodsStatus (data) {
      return new Promise(resolve => {
        changeGoodsStatus(data).then(res => {
          const DATA = res.data
          resolve(DATA)
          console.log(DATA)
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
          } else {
            this.$message({
              message: DATA.message,
              type: 'error'
            })
          }
        })
      })
    },
    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理
      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 获取最大的 catgory_id值
    MaxCatgroyId (arr, keyMap) {
      let maxid = 0
      for (const val of arr) {
        maxid = maxid > val[keyMap] ? maxid : val[keyMap]
      }
      return maxid
    },

    //切换tab 待审核、审核未通过
    swichTable (tab) {
      const name = tab.name
      this.activeTableName = name
      this.tabIndex = tab.index
      //刷新
      this.updataTableData();
    },
    //切换tab Dialog新增、编辑
    swichTab (tab) {
      const name = tab.name
      this.activeTabName = name
    },
    //刷新表格数据
    updataTableData () {
      console.log(this.activeTableName);
      if (this.activeTableName == 'first' || this.activeTableName == 'second' || this.activeTableName == 'third') {
        //审核未通过
        this.getGoodsReserveList();
      } else {
        this.getGoodsOperationList()
      }

    },

    // 添加数据操作
    clickAddData () {
      this.tableFlag = true
      let BasisGoodsList = this.getBasisGoodsList()
      let BrandList = this.getBrandList()
      this.openForm = this.$options.data().openForm
      Promise.all([BasisGoodsList, BrandList]).then(res => {
        this.dialogFonfig.isShowDialogVisible = true
        this.dialogFonfig.openType = 'add'
      })

    },

    // 确定提交数据操作
    async clickSubmitData (data) {

      const name = this.activeTabName;//当前Dialog Tab页面
      console.log(data)
      let res;//返回处理结果
      if (name == 'first') {
        this.activeTabName = 'second'
      } else {
        res = await this.saveMainnformation(data)
        if (res.code == 200) {
          this.updataTableData()
          //关闭 dialog
          this.dialogFonfig.isShowDialogVisible = false;
          this.openForm = this.$options.data().openForm;
        } else {
          this.$message({
            message: res.message,
            type: 'error'
          })
          // 处理规格表格数据
          let c = []
          for (var i = 0; i < this.shopSpec.length; i++) {
            c.push({
              name: "",
              value: [],
            });
          }
          this.shopSpec.forEach((item, index1) => {
            c[index1].name = item.name
            c[index1].value = item.value.map((item2) => {
              return {
                attr: item2,
              };
            });
          });
          this.shopSpec = c
        }
      }
    },
    //保存-基本信息
    async saveMainnformation (data) {
      let type = this.dialogFonfig.openType
      let result;//主信息上传是否成功
      //处理规格数据
      let c = []
      for (var i = 0; i < this.shopSpec.length; i++) {
        c.push({
          name: '',
          value: []
        })
      }
      this.shopSpec.forEach((item, index) => {
        c[index].name = item.name
        item.value.forEach(item2 => {
          c[index].value.push(item2.attr)
        })
      })
      this.shopSpec = c
      if (type == 'add') {
        if (data.sales_begin_time == '' || data.sales_end_time == '') {
          const DATA = {
            //基本信息
            no: data.no,
            name: data.name,
            big_images: data.big_images,
            images: data.images,
            goods_category_ids: data.goods_category_ids,
            // supplier_id: data.supplier_id,
            brand_id: data.brand_id[0],
            sort: data.sort,
            vr_model: data.vr_model,
            summary: data.summary,
            introduce: data.introduce,
            remark: data.remark,
            service_info: data.service_info,
            is_sales_time: data.is_sales_time,
            is_top: data.is_top,
            is_recommend: data.is_recommend,
            is_hot: data.is_hot,
            freight_price: data.freight_price,
            freight_info: data.freight_info,
            status: data.status,
            original_price: 1,
            cost_price: 1,
            sales_price: data.sales_price,
            vip_price: 1,
            checkout_price: data.checkout_price,
            stock_count: data.stock_count,
            goods_sku: this.specTableData,
            goods_sku_type: this.shopSpec
          }
          result = await this.metGoodsList('POST', DATA)
          data = result.data
        } else {
          const DATA = {
            //基本信息
            no: data.no,
            name: data.name,
            big_images: data.big_images,
            images: data.images,
            goods_category_ids: data.goods_category_ids,
            // supplier_id: data.supplier_id,
            brand_id: data.brand_id[0],
            sort: data.sort,
            vr_model: data.vr_model,
            summary: data.summary,
            introduce: data.introduce,
            remark: data.remark,
            service_info: data.service_info,
            is_sales_time: data.is_sales_time,
            is_top: data.is_top,
            is_recommend: data.is_recommend,
            is_hot: data.is_hot,
            freight_price: data.freight_price,
            freight_info: data.freight_info,
            sales_begin_time: data.sales_begin_time,
            sales_end_time: data.sales_end_time,
            status: data.status,
            original_price: 1,
            cost_price: 1,
            sales_price: data.sales_price,
            vip_price: 1,
            checkout_price: data.checkout_price,
            stock_count: data.stock_count,
            goods_sku: this.specTableData,
            goods_sku_type: this.shopSpec
          }
          result = await this.metGoodsList('POST', DATA)
          data = result.data
        }

      } else if (type == 'edit') {
        this.imgIndex.forEach(item => {
          data.images.splice(this.imgIndex, 1)
        })
        if (data.sales_begin_time == '' || data.sales_end_time == '') {
          const DATA = {
            //基本信息
            id: data.id,
            no: data.no,
            name: data.name,
            big_images: data.big_images,
            images: data.images,
            goods_category_ids: data.goods_category_ids,
            // supplier_id: data.supplier_id,
            brand_id: data.brand_id[0],
            sort: data.sort,
            vr_model: data.vr_model,
            summary: data.summary,
            introduce: data.introduce,
            remark: data.remark,
            service_info: data.service_info,
            is_sales_time: data.is_sales_time,
            is_top: data.is_top,
            is_recommend: data.is_recommend,
            is_hot: data.is_hot,
            freight_price: data.freight_price,
            freight_info: data.freight_info,
            status: data.status,
            original_price: 1,
            cost_price: 1,
            sales_price: data.sales_price,
            vip_price: 1,
            checkout_price: data.checkout_price,
            stock_count: data.stock_count,
            goods_sku: this.specTableData,
            goods_sku_type: this.shopSpec
          }
          result = await this.metGoodsList('PUT', DATA)
          data = result.data
        } else {
          const DATA = {
            //基本信息
            id: data.id,
            no: data.no,
            name: data.name,
            big_images: data.big_images,
            images: data.images,
            goods_category_ids: data.goods_category_ids,
            // supplier_id: data.supplier_id,
            brand_id: data.brand_id[0],
            sort: data.sort,
            vr_model: data.vr_model,
            summary: data.summary,
            introduce: data.introduce,
            remark: data.remark,
            service_info: data.service_info,
            is_sales_time: data.is_sales_time,
            is_top: data.is_top,
            is_recommend: data.is_recommend,
            is_hot: data.is_hot,
            freight_price: data.freight_price,
            freight_info: data.freight_info,
            sales_begin_time: data.sales_begin_time,
            sales_end_time: data.sales_end_time,
            status: data.status,
            original_price: 1,
            cost_price: 1,
            sales_price: data.sales_price,
            vip_price: 1,
            checkout_price: data.checkout_price,
            stock_count: data.stock_count,
            goods_sku: this.specTableData,
            goods_sku_type: this.shopSpec
          }
          result = await this.metGoodsList('PUT', DATA)
          data = result.data
        }
      }
      return result;
    },

    // 取消提交数据操作
    clickCancelData () {
      this.activeTabName = "first"
      this.dialogFonfig.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
      this.dialogFonfigAudit.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.auditName = this.$options.data().auditName
      this.priceForm = this.$options.data().priceForm
      this.shopSpec = []
      this.specTableData = []
      this.tableFlag = false
    },


    //获取从CommonUploadList传来的图片删除数据
    handleRemove (data) {
      this.imgIndex.push(data)
    },

    // 编辑操作
    async clickEditData (row) {
      this.tableFlag = true
      await this.getBasisGoodsList()//加载商品分类
      await this.getBrandList()//加载品牌分类
      // id	是	商品的Id
      const data = {
        id: row.id
      }
      let result = await this.getGoodsShow(data)
      let data2 = result.data.data
      this.specTableData = data2.goods_sku
      this.shopSpec = data2.goods_sku_type
      // console.log(this.specTableData, this.shopSpec)
      // 处理规格表格数据
      let c = []
      for (var i = 0; i < this.shopSpec.length; i++) {
        c.push({
          name: "",
          value: [],
        });
      }
      this.shopSpec.forEach((item, index1) => {
        c[index1].name = item.name
        c[index1].value = item.value.map((item2) => {
          return {
            attr: item2,
          };
        });
      });
      this.shopSpec = c

      this.imgIndex.forEach(item => {
        data2.images.splice(this.imgIndex, 1)
      })
      this.imgIndex = []
      if (data2.status == -1) {
        data2.status = 1
      } else {
        data2.status = data2.status
      }
      this.openForm = {
        id: data2.id,
        no: data2.no,
        name: data2.name,
        big_images: data2.big_images,
        images: data2.images,
        goods_category_ids: data2.goods_category_ids,
        // supplier_id: data2.supplier_id,
        brand_id: [data2.brand_id],
        sort: data2.sort,
        vr_model: data2.vr_model,
        summary: data2.summary,
        introduce: data2.introduce,
        remark: data2.remark,
        service_info: data2.service_info,
        is_sales_time: data2.is_sales_time,
        is_top: data2.is_top,
        is_recommend: data2.is_recommend,
        is_hot: data2.is_hot,
        freight_price: data2.freight_price,
        freight_info: data2.freight_info,
        sales_begin_time: data2.sales_begin_time,
        sales_end_time: data2.sales_end_time,
        status: data2.status,
        original_price: data2.original_price,
        cost_price: data2.cost_price,
        sales_price: data2.sales_price,
        vip_price: data2.vip_price,
        checkout_price: data2.checkout_price,
        stock_count: data2.stock_count,
      }
      console.log(this.openForm)
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },
    //详情 商品
    getGoodsShow (data) {
      return new Promise(resolve => {
        getGoodsShow(data).then((res) => {
          resolve(res)
        })
      })
    },

    //把数据展示到表格上
    calculateDate () {
      const result = this.TabsFormLabel[1].formTable
      console.log(result)
      const priceArray = {
        original_price: 1,
        cost_price: 1,
        sales_price: result.sales_price,
        vip_price: 1,
        stock_count: result.stock_count,
        freight_price: result.freight_price
      }
      this.shopTableData = this.shopTableData.concat(priceArray)
    },
    //删除价格
    clickDeleteEquip (row) {
      console.log(row)
      // const index = this.touristDatePrice.some((item, index) => {
      //   if (item.date == row.date) {
      //     this.touristDatePrice.splice(index, 1)
      //     return true
      //   }
      // })
    },
    //审核操作
    clickAuditData (row) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      // status	是	int	状态 1、通过 -1、驳回
      // reason	是	string	审核理由
      this.auditForm = {
        id: row.id,
        failed_reason: row.failed_reason,
        status: row.status,
      }
      this.dialogFonfigAudit.isShowDialogVisible = true;
    },
    //审核结果提交
    async clickSubmitAuditData (statu, data) {
      // ----------------数据格式-----------------
      //id	是	int	ID
      // status	是	int	状态 1、通过 -1、驳回
      const DATA = {
        id: data.id,
        reason: data.failed_reason,
        status: statu,
      }
      await this.auditGoodsList(DATA).then(async (res) => {
        if (res.code == 200) {
          await this.updataTableData();
        }
      });//调用审核接口 


      this.dialogFonfigAudit.isShowDialogVisible = false
    },
    //参考中和销售中的商品 改变状态
    changeSwitch (row) {
      // console.log(row.id)
      const data = {
        id: row.id,
        status: row.status == 0 ? 2 : 0
      }
      console.log(data)
      this.changeGoodsStatus(data)
      this.updataTableData()
    },

    // 删除操作
    clickDeleteData (row) {
      // ----------------数据格式-----------------
      //id	是	int	ID
      this.$confirm('确定移除这条记录吗？', '提示', { type: "error" }).then(() => {
        const DATA = {
          id: row.id
        }
        metGoodsList('DELETE', DATA).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message.success('删除成功！');
          }
          this.updataTableData();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    //处理标准时间
    dateToMs (date) {
      let result = new Date(date).getTime();
      return result;
    },
    // 查询操作
    searchHotelProductToAudit (searchForm) {
      var sales_price = []
      if (searchForm.sales_price1 === '' || searchForm.sales_price2 === '') {
        sales_price = []
      } else {
        sales_price = [searchForm.sales_price1, searchForm.sales_price2]
      }

      var vip_price = []
      if (searchForm.vip_price1 === '' || searchForm.vip_price2 === '') {
        vip_price = []
      } else {
        vip_price = [searchForm.vip_price1, searchForm.vip_price2]
      }

      var sales_begin_time = []
      if (searchForm.sales_begin_time1 === '' || searchForm.sales_begin_time2 === '') {
        sales_begin_time = []
      } else {
        sales_begin_time = [this.dateToMs(searchForm.sales_begin_time1) / 1000,
        this.dateToMs(searchForm.sales_begin_time2) / 1000]
      }

      var sales_end_time = []
      if (searchForm.sales_end_time1 === '' || searchForm.sales_end_time2 === '') {
        sales_end_time = []
      } else {
        sales_end_time = [this.dateToMs(searchForm.sales_end_time1) / 1000,
        this.dateToMs(searchForm.sales_end_time2) / 1000]
      }

      const data = {
        no_like: searchForm.no_like,
        name: searchForm.name,
        supplier_id: searchForm.supplier_id,
        sales_price: sales_price,
        vip_price: vip_price,
        sales_begin_time: sales_begin_time,

        sales_end_time: sales_end_time,

        is_sales_time: searchForm.is_sales_time,
        is_top: searchForm.is_top,
        is_recommend: searchForm.is_recommend,
        is_hot: searchForm.is_hot,
      }
      this.searchData = data
      if (this.activeTableName == 'first' || this.activeTableName == 'second' || this.activeTableName == 'third') {
        data.status = this.tabIndex
        data.page = this.tableFormLabel[this.tabIndex].tableConfig.page
        data.page_rows = this.tableFormLabel[this.tabIndex].tableConfig.NumArticle
        this.getGoodsReserveList(data)
      } else {
        data.status = -1
        data.page = this.tableFormLabel[3].tableConfig.page
        data.page_rows = this.tableFormLabel[3].tableConfig.NumArticle
        this.getGoodsOperationList(data)
      }
    },
    //重置筛选条件操作
    resetFilter (searchForm) {
      searchForm.no_like = '',
        searchForm.name = '',
        // searchForm.supplier_id = '',
        searchForm.sales_price1 = '',
        searchForm.sales_price2 = '',
        searchForm.vip_price1 = '',
        searchForm.vip_price2 = '',
        searchForm.sales_begin_time1 = '',
        searchForm.sales_begin_time2 = '',
        searchForm.sales_end_time1 = '',
        searchForm.sales_end_time2 = '',
        searchForm.is_sales_time = '',
        searchForm.is_top = '',
        searchForm.is_recommend = '',
        searchForm.is_hot = ''
      this.searchData = ''
      if (this.activeTableName == 'first' || this.activeTableName == 'second' || this.activeTableName == 'third') {
        this.getGoodsReserveList()
      } else {
        this.getGoodsOperationList()
      }
    },
    //点击放大图片
    handlePreview (file) {
      this.dialogImageUrl = file.url
      this.dialogImgVisible = true;
    },




    //规格操作方法

    // 添加规格行
    addStand (i) {
      this.formFlag = true
      //  限制规格种类不超过4种
      if (this.shopSpec.length > 3) {
        this.$message('不能超过四行')
      } else {
        this.shopSpec.push({ name: '', value: [] })
      }
    },

    // 添加规格表格
    getTable () {
      this.isTable = true
      this.specTableData = []
      this.group = []
      this.listL = []
      //   console.log(this.shopSpec);
      const num = this.shopSpec.length
      this.shopSpec.forEach(item => {
        this.listL.push(item.value)
      });
      //   console.log(this.list);
      var arr = []
      var that = this
      function func (skuarr = [], i) {
        for (let j = 0; j < that.listL[i].length; j++) {
          if (i < that.listL.length - 1) {
            skuarr[i] = that.listL[i][j].attr
            func(skuarr, i + 1)
          } else {
            arr.push([...skuarr, that.listL[i][j].attr])
          }
        }
        return arr
      }
      let newList = func([], 0)
      let b
      newList.forEach(item => {
        b = ''
        for (let i = 0; i < num; i++) {
          let a = this.shopSpec[i].name
          a = a + ':' + item[i]
          b = b + a + ';'
        }
        this.group.push(b)
      })
      let table = []
      console.log(this.priceForm.checkout_price)
      for (let j = 0; j < this.group.length; j++) {
        table.push({
          name: this.group[j],
          no: this.priceForm.no,
          original_price: 1,
          cost_price: 1,
          sales_price: this.priceForm.sales_price,
          vip_price: 1,
          freight_price: this.priceForm.freight_price,
          stock_count: this.priceForm.stock_count,
          integral: this.priceForm.integral == '' ? 0 : this.priceForm.integral,
          checkout_price: this.priceForm.checkout_price,
          sales_count: this.priceForm.sales_count,
          note: this.priceForm.note,
          is_coupon: this.priceForm.is_coupon
        })
      }
      this.specTableData = table
      // this.specTableData = data
      // this.shopSpec = shopSpec
      // console.log(shopSpec)
      // this.shopSpec.forEach(item=>{
      //   console.log()
      // })

      console.log(this.shopSpec)
    },
    //重置表格
    resetTable () {
      this.specTableData = []
    },
    // 删除规格行
    remove (i) {
      this.shopSpec.splice(i, 1)
    },
    // 添加属性值
    addAtrr (i) {
      //  限制属性值不超过10个
      if (this.shopSpec[i].value.length > 10) {
        this.$message('不能超过10个')
      } else {　　　　　　　　// 存的时候是存的对象
        this.shopSpec[i].value.push({ attr: '' })
      }
    },
    // 删除属性值
    closeAtrr (a, b) {
      //   console.log(a, b);
      this.shopSpec[a].value.splice(b, 1)
    },
    //上传图片
    async uploadFile (param, item) {
      return new Promise((resolve, reject) => {
        const image = param.file
        const isSzie = image.size / 1024 < item.maxSize || 100;
        if (!isSzie) {
          param.onError("上传图片大小不能超过 " + item.maxSize || 100 + "KB!")
          return;
        }
        uploadFile(image).then((res) => {
          this.uploadSuccess(item, res)
          return resolve(item, res);
        })

      })
    },

    //图片上传成功操作
    uploadSuccess (item, url) {
      this.$message({
        message: '图片上传成功',
        type: 'success'
      })
      console.log(this.$refs.specImage[0].width)
      this.specTableData[this.specImgIndex].image = url
      this.$refs.specImage[0].width++
      console.log(this.specTableData[this.specImgIndex].image)
      this.specImgIndex = ''

    },
    //图片上传失败操作
    uploadError (err) {
      this.$message.error(err);
    },
    //注意这里是解构
    //利用单元格的 className 的回调方法，给行列索引赋值
    tableCellClassName ({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex;
      column.index = columnIndex;
    },
    //
    cellClick (row) {
      this.specImgIndex = row.index
    }
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  .content {
    height: 100%;
  }
  .manage-content {
    /deep/.el-tabs__content {
      height: 806px;
    }
  }

  /deep/.el-dialog {
    width: 86.25%;
  }
  .dialogAudit {
    /deep/ .el-dialog__body {
      padding-right: 45%;
    }
  }
  /deep/ .el-table .cell.el-tooltip {
    white-space: normal;
    text-align: left;
  }
  .filtrateInput {
    background: #f8f7f7;
    padding: 24px 8px;
    margin-top: 51px;
    width: 100%;
    margin-left: -128px;
    margin-bottom: 32px;
  }
  .title {
    width: 100%;
    background-color: #f8f7f7;
    height: 22px;
    padding-top: 10px;
    padding-left: 10px;
  }
}

// 规格表格部分的样式
li {
  list-style: none;
}
.add {
  // margin-top: 20px;
  background: #f8f7f7;
  margin-left: -40px;
  div {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.add2 {
  background: #f8f7f7;
  border: 0.5px solid #dcdfe6;
  div {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.attr {
  margin-bottom: 10px;
}
.el-input {
  width: auto;
}
.puttAll {
  height: 60px;
  display: inline-block;
  // background-color: rosybrown;
  position: relative;
  span {
    position: relative;
    top: 0px;
    margin-left: 12px;
    line-height: 60px;
  }
  .putt {
    display: inline-block;
    position: relative;
    // margin-left: 36px;
    margin-top: 10px;
    .el-input {
      margin-left: 20px;
    }
  }
}
.append {
  // width: 40px;
  // height: 40px;
  // background-color: aqua;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  display: inline-block;
  color: #409eff;
  cursor: pointer;
  vertical-align: middle;
  margin-left: 20px;
}
.title {
  background-color: bisque;
  margin-right: 10px;
}
.close {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: burlywood;
  border-radius: 50%;
  line-height: 15px;
  text-align: center;
  right: -5px;
  top: -5px;
}
.shop_all {
  display: flex;
  margin-top: 20px;
  .shop_title {
    display: inline-block;
    width: 12%;
    text-align: center;
  }
  .shop_value {
    width: 90%;
    border: 1px solid #dcdfe6;
    // display: inline-block;
    .spec_name {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      height: 50px;
      background-color: #f8f7f7;
      span {
        color: #409eff;
        cursor: pointer;
        float: right;
        margin-right: 10px;
        margin-top: 10px;
      }
    }
    .spec_value {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      background-color: white;
      div {
        display: inline-block;
      }
      a {
        color: #409eff;
        font-size: 12px;
        // margin-left: 10px;
        cursor: pointer;
      }
    }
    .spec_btn {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      // width: 800px;
      background-color: #f8f7f7;
    }
  }
}
.stand {
  ul {
    border: 1px solid #dcdfe6;
    .spec_name {
      margin-left: -40px;
      width: 100.5%;
      background: #f8f7f7;
      padding-right: 35px;
      height: 60px;
      .name {
        margin-left: 20px;
        margin-right: 12px;
      }
    }
  }
}
.table {
  /deep/.el-table__header {
    width: 100% !important;
  }
  /deep/.el-table__body {
    width: 100% !important;
  }
}
.avatar-uploader {
  display: flex;
  /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
  }
  .tooltip {
    flex: 1;
    align-self: center;
  }
}
.avatar-uploader-icon {
  font-size: 20px;
  color: #8c939d;
  width: 64px;
  height: 46px;
  text-align: center;
  margin-top: 25px;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
</style>