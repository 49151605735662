import { request } from './request'
import qs from 'qs'


/***
 * 
 * --------------------商品订单--------------------
 */
// 获取商品订单列表
export function getShopOrder (params) {
  return request({
    url: '/supplier/shop/order',
    method: 'GET',
    params
  })
}

// 订单详单发货信息设置
export function deliveryOrderDetail (datas){
  return request({
    url:'/supplier/shop/order/detail/delivery',
    method:'PUT',
    data: qs.stringify(datas)
  })
}

// 订单详单发货
export function shippedOrder (datas){
  return request({
    url:'/supplier/shop/order/detail/shipped',
    method:'PUT',
    data: qs.stringify(datas)
  })
}

/***
 * 
 * --------------------商品管理--------------------
 */
// 获取商品管理列表
export function getGoodsList (params) {
  return request({
    url: 'supplier/goods',
    method: 'GET',
    params
  })
}

// 商品管理 操作 数据
export function metGoodsList (method, datas) {
  return request({
    url: 'supplier/goods',
    method,
    data: qs.stringify(datas)
  })
}

// 商品管理 审核 数据
export function auditGoodsList (datas) {
  return request({
    url: '/supplier/goods/review',
    method:'PUT',
    data: qs.stringify(datas)
  })
}
// 商品管理 改变状态 数据
export function changeGoodsStatus (datas) {
  return request({
    url: '/supplier/goods/status',
    method:'PUT',
    data: qs.stringify(datas)
  })
}
// 获取商品管理列表详情
export function getGoodsShow (params) {
  return request({
    url: '/supplier/goods/read',
    method: 'GET',
    params
  })
}


/***
 * 
 * --------------------快递/物流公司管理--------------------
 */

// 获取快递/物流公司列表
export function getLogisticsList (params) {
  return request({
    url: '/supplier/shop/express_com',
    method: 'GET',
    params
  })
}

// 快递公司管理 操作 数据
export function metLogisticsList (method, datas) {
  return request({
    url: '/supplier/shop/express_com',
    method,
    data: qs.stringify(datas)
  })
}

// 快递公司状态变更
export function putStatusLogisticsData (method, datas) {
  return request({
    url: '/supplier/shop/express_com/status',
    method,
    data: qs.stringify(datas)
  })
}