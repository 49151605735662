<template>
  <div id="CommonManageMain">
    <div class="manage-header">
      <slot name="header"></slot>
    </div>

    <div class="manage-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
#CommonManageMain {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  height: 100%;

  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .manage-content {
    flex: 1;
  }

  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height: 6px; // 纵向滚动条 必写
  }

  // 滚动条的滑块
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 3px;
  }
}
</style>
