import { render, staticRenderFns } from "./CommonManageMain.vue?vue&type=template&id=50f205ac&scoped=true&"
import script from "./CommonManageMain.vue?vue&type=script&lang=js&"
export * from "./CommonManageMain.vue?vue&type=script&lang=js&"
import style0 from "./CommonManageMain.vue?vue&type=style&index=0&id=50f205ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f205ac",
  null
  
)

export default component.exports